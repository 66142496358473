* {
  font-family: "Lato", sans-serif;
}
textarea:focus,
input:focus {
  outline: none;
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
}

#app {
  background-color: #a442e6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23552277' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%23a442e6' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%239d3edd' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23963ad4' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%238f36cb' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%238832c2' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23812db9' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%237a29b0' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%237325a7' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%236c219f' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23661e96' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%235f1a8e' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23591685' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%2352117d' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%234c0d75' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%2345096d' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%233f0565' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%2339025d' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23330055' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
}
.white-background {
  background: white !important;
}

.hide {
  display: none;
}

.light {
  font-weight: 300;
}
.more-lh {
  line-height: 1.5;
}
.opa-hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

#bq-main-logo {
  height: 32px;
  margin-right: 14px;
}
#query-box {
  /* max-width: 1000px; */
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#query {
  width: 300px;
  border: none;
  border-radius: 6px;
  padding: 6px 6px 6px 14px;
  font-weight: 300;
  font-size: 18px;
}
.white-query-box {
  background-color: rgba(255, 255, 255, 0.479);
  color: white;
}
.white-query-box::placeholder {
  color: rgba(255, 255, 255, 0.623);
}

.gray-query-box {
  background-color: rgba(212, 212, 212, 0.479);
  color: black;
}
.gray-query-box::placeholder {
  color: gray;
}

.bq-white-btn {
  background-color: White;
  color: #2a0046;
  height: 50px;
  /* width: 240px; */
  font-size: 18px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bq-white-btn:hover {
  position: relative;
  bottom: 1px;
  opacity: 0.87;
}
.bq-purple-btn {
  background-color: #2a0046;
  color: white;
  height: 50px;
  /* width: 180px; */
  font-size: 18px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bq-purple-btn:hover {
  position: relative;
  bottom: 1px;
  opacity: 0.87;
}
.flex-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 330055
 DD00FF */

/* 552277 */

/* A442E6 */

.bq-tbl-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  /* justify-content: center; */
  cursor: default;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: auto;
}
.bq-tbl-row:hover {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.bq-tbl-cell {
  padding: 6px;
  width: 10%;
  min-width: 100px;
}
